body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #eee;

  display: flex;
  align-items: center;
  justify-content: center;

  font-family: tahoma,sans-serif;
}

.card-header {
  background-color: rgba(0,0,0, 0.2);
}

.form-wrapper {
  padding: 1rem;
  border: 1px solid rgba(0,0,0, 0.2);
  width: 24rem;
  background-color: rgba(0,0,0, 0.1);
  border-radius: 0.25rem;
  box-shadow: 0 0 0.5rem rgba(0,0,0, 0.05);
}

iframe {
  width: 100%;
  height: 100%;
  border: 0;
}

.field input {
  width: 100%;
  height: 100%;
  border: 0;
}

.field {
  height: 1.5rem;
  margin-bottom: 1rem;
  background-color: rgba(255,255,255, 0.5);
  border: 1px solid rgba(0,0,0, 0.2);
  border-radius: 0.25rem;
  padding: 0.25rem;
}

.field-group {
  display: flex;
}

#card-submit-btn {
  margin-bottom: 0;
  height: 2rem;
  background-color: #98cbff;
}

#card-expiration-date {
  margin-left: 0.5rem;
}

.init-demo-form, .cc-loading {
  text-align: center;
}

.cc-done, .cc-info {
  text-align: center;
}

.cc-error {
  display: none;
  background-color: tomato;
  border: 1px solid rgba(0,0,0,0.1);
  color: #fff;
  padding: 0.5rem;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
  text-align: center;
}

.cc-done h3, .cc-info h3 {
  margin: 0;
  margin-bottom: 1rem;
  font-weight: bold;
}

p {
  margin: 0;
}

button.field {
  margin: 0;
  background-color: rgba(0,0,0, 0.05);
  border-radius: 0.25rem;
  box-shadow: 0 0 0.5rem rgba(0,0,0, 0.05);
}

button.field:hover {
  margin: 0;
  background-color: rgba(0,0,0, 0.1);
}

button.field:active {
  margin: 0;
  background-color: rgba(0,0,0, 0.2);
}

.field--error {
  display: none;
  background-color: tomato;
}

.field--error.has-error {
  display: block;
}

.cc-form {
  display: none;
}

/* Uses bootstrap-material-design - https://fezvrasta.github.io/bootstrap-material-design */

.panel {
  width: 80%;
  margin: 2em auto;
}

.panel-body {
  width: 90%;
  margin: 2em auto;
}

body {
  font-size: 15px;
}

label {
  font-weight: 400;
}

.helper-text {
  color: #E91E63; 
  font-size: 12px;
  margin-top: 5px;
  height: 12px;
  display: block;
}

.helper-text-green {
  color: green;
}


/* Hosted Payment Fields styles*/
.hosted-field-focus { 
  outline: none;
  background-image: linear-gradient(#009688, #009688), linear-gradient(#d2d2d2, #d2d2d2);
  animation: input-highlight 0.5s forwards;
  box-shadow: none;
  background-size: 0 2px, 100% 1px;
}
