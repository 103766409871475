body, html {
  height: 100%;
  background-color: #eee;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  font-family: tahoma, sans-serif;
  display: flex;
}

.card-header {
  background-color: #0003;
}

.form-wrapper {
  width: 24rem;
  background-color: #0000001a;
  border: 1px solid #0003;
  border-radius: .25rem;
  padding: 1rem;
  box-shadow: 0 0 .5rem #0000000d;
}

iframe, .field input {
  width: 100%;
  height: 100%;
  border: 0;
}

.field {
  height: 1.5rem;
  background-color: #ffffff80;
  border: 1px solid #0003;
  border-radius: .25rem;
  margin-bottom: 1rem;
  padding: .25rem;
}

.field-group {
  display: flex;
}

#card-submit-btn {
  height: 2rem;
  background-color: #98cbff;
  margin-bottom: 0;
}

#card-expiration-date {
  margin-left: .5rem;
}

.init-demo-form, .cc-loading, .cc-done, .cc-info {
  text-align: center;
}

.cc-error {
  color: #fff;
  text-align: center;
  background-color: tomato;
  border: 1px solid #0000001a;
  border-radius: .25rem;
  margin-bottom: 1rem;
  padding: .5rem;
  display: none;
}

.cc-done h3, .cc-info h3 {
  margin: 0 0 1rem;
  font-weight: bold;
}

p {
  margin: 0;
}

button.field {
  background-color: #0000000d;
  border-radius: .25rem;
  margin: 0;
  box-shadow: 0 0 .5rem #0000000d;
}

button.field:hover {
  background-color: #0000001a;
  margin: 0;
}

button.field:active {
  background-color: #0003;
  margin: 0;
}

.field--error {
  background-color: tomato;
  display: none;
}

.field--error.has-error {
  display: block;
}

.cc-form {
  display: none;
}

.panel {
  width: 80%;
  margin: 2em auto;
}

.panel-body {
  width: 90%;
  margin: 2em auto;
}

body {
  font-size: 15px;
}

label {
  font-weight: 400;
}

.helper-text {
  color: #e91e63;
  height: 12px;
  margin-top: 5px;
  font-size: 12px;
  display: block;
}

.helper-text-green {
  color: green;
}

.hosted-field-focus {
  box-shadow: none;
  background-image: linear-gradient(#009688, #009688), linear-gradient(#d2d2d2, #d2d2d2);
  background-size: 0 2px, 100% 1px;
  outline: none;
  animation: input-highlight .5s forwards;
}

/*# sourceMappingURL=index.0b4f312b.css.map */
